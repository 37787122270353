<script>
import Layout from "../../layouts/main.vue";
import axios from "axios";
import Swal from "sweetalert2";


export default {
  components: {
    Layout,
  },
  data() {
    return {
      doctor: []
    };
  },
  methods: {
    async getDoctor() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/doctor/" + this.$route.params.id
      );
      this.doctor = response.data;
    },
    successmsg() {
      Swal.fire("Good job!", "Updated the details of doctor ", "success");
    },
    async updateDoctor() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("name", this.doctor.name);
      urlencoded.append("email", this.doctor.email);
      urlencoded.append("image", this.doctor.image);
      urlencoded.append("phone", this.doctor.phone);
      urlencoded.append("address", this.doctor.address);
      urlencoded.append("expert", this.doctor.expert);
      urlencoded.append("degree", this.doctor.degree);
      urlencoded.append("status", this.doctor.status);

      await axios.put(
        "https://api.doctosoft.com/index.php/api/doctor" + "/" + this.$route.params.id,
        urlencoded
      );
      this.successmsg()
      this.$router.push("/admin/doctor");
    },
  },
  created() {
    this.getDoctor();

  },
};
</script>
<template>
  <Layout>
    <div class="back-btn">
      <div class="arrow">
        <button class="btn btn-light" @click="$router.push('/admin/doctor')"><i class="mdi mdi-chevron-left"></i></button>
      </div>
      <div class="action-btn">
        <b-button class="btn-soft-info waves-effect waves-light" @click="$router.push('/admin/doctor')">Cancel</b-button>
        <button class="btn  btn-md btn btn-primary" type="submit" @click="updateDoctor">Save</button>
      </div>
    </div>
    <b-row class="card-main">
      <b-col sm="6" xl="10">
        <b-card no-body>
          <form class="needs-validation" @submit.prevent="updateDoctor">
            <div class="mb-3">
              <label for="name" class="form-label">Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="doctor.name" placeholder="Enter Name" />
            </div>

            <div class="mb-3">
              <label for="email" class="form-label">Email <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="doctor.email" placeholder="Enter Email" />
            </div>

            <div class="mb-3">
              <label for="image" class="form-label">Image <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="doctor.image" placeholder="Enter image" />
            </div>

            <div class="mb-3">
              <label for="phone" class="form-label">Phone <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="doctor.phone" placeholder="Enter phone" />
            </div>

            <div class="mb-3">
              <label for="address" class="form-label">Address <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="doctor.address" placeholder="Enter address" />
            </div>

            <div class="mb-3">
              <label for="expert" class="form-label">Expert <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="doctor.expert" placeholder="Enter expert" />
            </div>

            <div class="mb-3">
              <label for="degree" class="form-label">Degree <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="doctor.degree" placeholder="Enter degree" />
            </div>

            <div class="mb-3">
              <label for="status" class="form-label">Status <span class="text-danger">*</span></label>
              <select class="form-select" aria-label="size 3 select example" v-model="doctor.status">
                <option value="true">Enable</option>
                <option value="false">Disable</option>
              </select>
            </div>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>
